import {Component} from '@angular/core';
import {AuthService} from '../shared/service/auth.service';

@Component({
  selector: 's-poll-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent {

  constructor(public authService: AuthService) {
  }

  logout() {
    this.authService.logoutUser();
  }

}
