import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './shared/guards/auth.guard';

const appRoutes: Routes = [
  {
    path: '',
    loadChildren: './home/home.module#HomeModule'
  },
  {
    path: 'history',
    loadChildren: './poll-history/poll-history.module#PollHistoryModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'create',
    loadChildren: './create-poll/create-poll.module#CreatePollModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginModule'
  },
  {
    path: 'error',
    loadChildren: './error-page/error-page.module#ErrorPageModule'
  },
  {
    path: 'maintenance',
    loadChildren: './maintenance-page/maintenance-page.module#MaintenancePageModule'
  },
  {
    path: '**',
    redirectTo: 'error/404'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
