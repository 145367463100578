
import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[sPollTrimInput]'
})
export class TrimInputDirective {

  constructor(private element: ElementRef){
  }

  @HostListener('focusout', ['$event'])
  onFocusOut(event: Event): void {
    const inputValue = event.target['value'];
    this.element.nativeElement.value = inputValue.trim();
  }

}
