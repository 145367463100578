
import {Injectable} from '@angular/core';
import {JwtPayload} from '../types/jwt-payload.type';
import {ValidatorUtils} from '../validators/validator-utils';
import {ToastyMessageService} from './toasty-message.service';
import {Router} from '@angular/router';

const TOKEN_KEY = 'AuthToken';
const JWT_SEPARATOR = '.';
export const BEARER_KEY = 'Bearer ';

@Injectable()
export class TokenStorageService {

  constructor(private router: Router,
              private messageService: ToastyMessageService) {
  }

  clear(autoLogout = false): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.clear();
    if (autoLogout) {
      this.router.navigate(['/login']);
      this.messageService.showWarning('Platnost přihlášení vypršela. Došlo k automatickému odhlášení.', true);
    }
  }

  saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token.replace(BEARER_KEY, ''));
  }

  getToken(): string {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  existValidToken(): boolean {
    const payload: JwtPayload = this.parseTokenPayload();
    if (payload) {
      if ((payload.exp * 1000) < Date.now()) {
        this.clear(true);
        return false;
      }
      return true;
    }
    this.clear();
    return false;
  }

  parseTokenPayload(): JwtPayload {
    const token = this.getToken();
    if (ValidatorUtils.isNotEmptyString(token)) {
      const parsedToken = token.split(JWT_SEPARATOR);
      if (parsedToken && parsedToken.length === 3) {
        const encodedPayload = parsedToken[1];
        if (ValidatorUtils.isNotEmptyString(encodedPayload)) {
          return JSON.parse(atob(encodedPayload)) as JwtPayload;
        }
      }
    }
    return null;
  }

}
