
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {CreatePollRequest} from '../types/requests/create-poll-request.type';
import {Observable} from 'rxjs';
import {Poll} from '../types/poll.type';
import {environment} from '../../../environments/environment';
import {PollState} from '../types/poll-state.enum';
import {VoteAnswersRequest} from '../types/requests/vote-answers-request.type';
import {ChangePollStateRequest} from '../types/requests/change-poll-state-request.type';
import {BrowserFingerprintHolder} from './browser-fingerprint-holder.service';

const HTTP_HEADERS = new HttpHeaders(
  {
    'Content-Type': 'application/json'
  }
);

@Injectable()
export class PollManagementService {

  constructor(private http: HttpClient,
              private browserFingerprintHolder: BrowserFingerprintHolder) {
  }

  checkPollState(poll: Poll, checkedState: PollState): boolean {
    return poll.state === PollState.getName(checkedState);
  }

  createPoll(data: CreatePollRequest): Observable<Poll> {
    return this.http.post<Poll>(environment.urls.v1.createPoll, data, {headers: HTTP_HEADERS});
  }

  getPollList(params = new HttpParams()): Observable<Poll[]> {
    return this.http.get<Poll[]>(environment.urls.v1.getPolls, {params: params, headers: HTTP_HEADERS});
  }

  getActivePolls(): Observable<Poll[]> {
    return this.http.get<Poll[]>(environment.urls.v1.getActivePolls, {headers: HTTP_HEADERS});
  }

  voteAnswers(data: VoteAnswersRequest): Observable<Poll> {
    const url = environment.urls.v1.voteAnswer.replace('{pollId}', String(data.pollId));
    const headers = HTTP_HEADERS.append('X-browser', this.browserFingerprintHolder.fingerprint);
    return this.http.put<Poll>(url, {answerIds: data.answerIds}, {headers: headers});
  }

  changePollState(data: ChangePollStateRequest): Observable<Poll> {
    const url = environment.urls.v1.changePoll.replace('{pollId}', String(data.pollId));
    return this.http.put<Poll>(url, {newState: data.newState}, {headers: HTTP_HEADERS});
  }
}
