
import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {ValidatorUtils} from '../validators/validator-utils';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe extends DatePipe implements PipeTransform {

  transform(value: Date, nullMessage = 'nezadáno'): string {
    const transformed = super.transform(value, 'dd. MM. yyyy, HH:mm');
    return ValidatorUtils.isEmptyString(transformed) && nullMessage
      ? nullMessage
      : transformed;
  }
}
