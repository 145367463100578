import { Component } from '@angular/core';
import {AuthService} from './shared/service/auth.service';

@Component({
  selector: 's-poll-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(private authService: AuthService) {
  }

  showMenu(): boolean {
    return this.authService.isUserLoggedIn();
  }

}
