
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import * as SockJs from 'sockjs-client';
import * as Stomp from 'stompjs';

@Injectable()
export class WebSocketService {

  public connection() {
    const socket = new SockJs(environment.urls.v1.webSockerAnswers);
    const connection = Stomp.over(socket);
    if (environment.stomp.disableDebug) {
      connection.debug = null;
    }
    return connection;
  }

}
