import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 's-poll-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {

  constructor(private router: Router) {
  }

  currentYear(): number {
    return new Date().getFullYear();
  }

  loginPage(): void {
    this.router.navigate(['/login']);
  }
}
