
import {Pipe, PipeTransform} from '@angular/core';
import {PollState} from '../types/poll-state.enum';

@Pipe({
  name: 'pollState'
})
export class PollStateToStringPipe implements PipeTransform {

  transform(value: PollState): string {
    return `${PollState[value]}`;
  }

}
