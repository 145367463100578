
import {Directive, HostListener} from '@angular/core';
import {FormArray, FormBuilder, NgControl} from '@angular/forms';
import {NewAnswerFormData} from '../../create-poll/types/new-answer-form-data.type';
import {ValidatorUtils} from '../validators/validator-utils';

@Directive({
  selector: '[sPollAutoAnswers]'
})
export class AutoAnswersDirective {

  constructor(private control: NgControl,
              private formBuilder: FormBuilder) {
  }

  @HostListener('input')
  onInput(): void {
    const answersFormArray: FormArray = this.control.control.parent.parent as FormArray;
    const newAnswers = answersFormArray.value;
    const filledAnswers = newAnswers.filter((value: NewAnswerFormData) => {
      return value && ValidatorUtils.isNotEmptyString(value.answer);
    });

    if (newAnswers.length - filledAnswers.length === 0) {
      this.addAnswer(answersFormArray);
    }
    
    // const inputValue: string = event.target['value'];
    // if (ValidatorUtils.isEmptyString(inputValue)
    //   && newAnswers.length - filledAnswers.length > 1
    //   && newAnswers.length > INITIAL_ANSWERS_COUNT) {
    // todo: implement remove
    // }
  }

  addAnswer(formArray: FormArray): void {
    // BEWARE: this code should be equal to the same in CreatePollComponent
    formArray.push(
      this.formBuilder.group(
        {
          answer: ''
        }
      )
    );
  }

}
