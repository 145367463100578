
export class ValidatorUtils {

  public static isNotEmptyString(value: string, trim = true): boolean {
    const checkedValue = value && trim ? value.trim() : value;
    return checkedValue && checkedValue.length > 0;
  }

  public static isEmptyString(value: string, trim = true): boolean {
    return !ValidatorUtils.isNotEmptyString(value, trim);
  }

}
