
import {EnumValues} from 'enum-values';

export enum PollState {
  ACTIVATED = 'Hlasování probíhá',
  DEACTIVATED = 'Hlasování ukončeno',
  PREPARED = 'Připraveno',
  ARCHIVED = 'Archivováno'
}

export namespace PollState {

  export function getName(state: PollState): PollState {
    return EnumValues.getNameFromValue(PollState, state) as PollState;
  }

}
