
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {BEARER_KEY, TokenStorageService} from '../shared/service/token-storage.service';
import {catchError} from 'rxjs/operators';
import {ErrorResponse} from '../shared/types/error-response.type';
import {Router} from '@angular/router';
import {ToastyMessageService} from '../shared/service/toasty-message.service';

const AUTH_REQUIRES_URL_PATHS = [
  '/v1/polls',
  '/v1/polls/{pollId}'
];
const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private messageService: ToastyMessageService,
              private tokenStorage: TokenStorageService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authRequest = this.tokenStorage.existValidToken() ? this.requestWithAuthHeader(request) : request;
    return next.handle(authRequest)
      .pipe(
        catchError(
          (err: HttpErrorResponse) => {
            const response: ErrorResponse = err.error;
            if (response) {
              const normalizedPath = response.requiredPath.replace(/(\/)\d+/, '/{pollId}');
              if (AUTH_REQUIRES_URL_PATHS.includes(normalizedPath)) {
                if (response.httpStatus.code === '401') {
                  this.tokenStorage.clear();
                  this.router.navigate(['/login']);
                  this.messageService.showWarning('Před provedením požadavku je potřeba se přihlásit.');
                } else if (response.httpStatus.code === '403') {
                  this.messageService.showError('Pro provedení požadavku nemáte dostatečná práva.');
                }
              }
            } else {
              this.messageService.showError();
            }
            return throwError(err);
          }
        ),
      );
  }

  private requestWithAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set(
        TOKEN_HEADER_KEY, `${BEARER_KEY}${this.tokenStorage.getToken()}`
      )
    });
  }

}
