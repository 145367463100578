
import {Injectable} from '@angular/core';
import {ToastyConfig, ToastyService} from 'ng2-toasty';
import {environment} from '../../../environments/environment';

@Injectable()
export class ToastyMessageService {

  // see: https://www.npmjs.com/package/ng2-toasty
  constructor(private toastyService: ToastyService, private toastyConfig: ToastyConfig) {
    this.toastyConfig.limit = 5;
    this.toastyConfig.showClose = false;
    this.toastyConfig.position = 'bottom-left';
    this.toastyConfig.timeout = environment.toastyMessageTimeout;
    this.toastyConfig.theme = 'default';
  }

  showSuccess(message = 'Akce proběhla úspěšně.'): void {
    this.toastyService.success(message);
  }

  showError(message = 'Požadavek se nepodařilo zpracovat!'): void {
    this.toastyService.error(message);
  }

  showWarning(message, longtimeMsg = false): void {
    if (message) {
      const timeout = longtimeMsg ? environment.toastyMessageLongTimeout : environment.toastyMessageTimeout;
      this.toastyService.warning({
        title: message,
        timeout: timeout,
        showClose: longtimeMsg
      });
    }
  }
}
