
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthRequest} from '../types/requests/auth-request.type';
import {TokenStorageService} from './token-storage.service';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {JwtPayload} from '../types/jwt-payload.type';

@Injectable()
export class AuthService {

  constructor(private http: HttpClient,
              private tokenStorage: TokenStorageService,
              private router: Router) {
  }

  auth(credentials: AuthRequest): Observable<HttpResponse<Object>> {
    return this.http.post<HttpResponse<Object>>(environment.urls.v1.login, credentials, {observe: 'response'});
  }

  logoutUser(): void {
    this.tokenStorage.clear();
    this.router.navigate(['']);
  }

  isUserLoggedIn(): boolean {
    // todo: try to rework usage of this method to structural directive
    // BEWARE: directive must dynamically react to token change.
    // E.g. must hide the button 'Ukončit hlasování' at home page after logout button was pressed
    return this.tokenStorage.existValidToken();
  }

  getUserLogin(): string {
    const payload: JwtPayload = this.tokenStorage.parseTokenPayload();
    return payload ? payload.sub : null;
  }

}
