
import {ModuleWithProviders, NgModule} from '@angular/core';
import {DateTimePipe} from './pipes/date-time.pipe';
import {BooleanToStringPipe} from './pipes/boolean-to-string.pipe';
import {PollStateToStringPipe} from './pipes/poll-state-to-string.pipe';
import {ToastyMessageService} from './service/toasty-message.service';
import {TrimInputDirective} from './directives/trim-input.directive';
import {PollManagementService} from './service/poll-management.service';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {AuthService} from './service/auth.service';
import {TokenStorageService} from './service/token-storage.service';
import {AuthGuard} from './guards/auth.guard';
import {AutoAnswersDirective} from './directives/auto-answers.directive';
import {WebSocketService} from './service/web-socket.service';
import {BusyButtonComponent} from './components/busy-button.component';
import {BrowserFingerprintHolder} from './service/browser-fingerprint-holder.service';

@NgModule({
  declarations: [
    DateTimePipe,
    PollStateToStringPipe,
    BooleanToStringPipe,
    TrimInputDirective,
    AutoAnswersDirective,
    BusyButtonComponent
  ],
  imports: [
    HttpClientModule,
    RouterModule
  ],
  exports: [
    RouterModule,
    DateTimePipe,
    PollStateToStringPipe,
    BooleanToStringPipe,
    TrimInputDirective,
    AutoAnswersDirective,
    BusyButtonComponent
  ]
})
export class SharedModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        ToastyMessageService,
        PollManagementService,
        AuthService,
        TokenStorageService,
        AuthGuard,
        WebSocketService,
        BrowserFingerprintHolder
      ]
    };
  }

}
