import {Component, Input} from '@angular/core';

@Component({
  selector: 's-poll-busy-button',
  templateUrl: './busy-button.component.html',
})
export class BusyButtonComponent {

  @Input() type: string;
  @Input() css: string;
  @Input() isBusy: boolean;

}
