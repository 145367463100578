import {Injectable} from '@angular/core';
import * as Fingerprint2 from 'fingerprintjs2';

@Injectable()
export class BrowserFingerprintHolder {

  private browserFingerprint: string;

  constructor() {
    this.computeFingerprint();
  }

  get fingerprint(): string {
    return this.browserFingerprint;
  }

  private computeFingerprint(): void {
    Fingerprint2.getPromise().then(components => {
      const values = components.map(function (component) {
        return component.value;
      }).join('');
      this.browserFingerprint = Fingerprint2.x64hash128(values, 31);
    });
  }

}
