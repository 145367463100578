
import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {ToastyMessageService} from '../service/toasty-message.service';
import {AuthService} from '../service/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,
              private messageService: ToastyMessageService,
              private router: Router) {
  }

  canActivate(): boolean {
    const canActivate = this.authService.isUserLoggedIn();
    if (!canActivate) {
      this.messageService.showError('Přístup na požadovanou stránku byl zamítnut!');
      this.router.navigate(['error/403']);
    }
    return canActivate;
  }

}
