
export const environment = {
  production: false,
  toastyMessageTimeout: 5000, // 5 seconds
  toastyMessageLongTimeout: 30000, // 30 seconds
  urls: {
    v1: {
      createPoll: 'https://test-straw-poll-restapi.herokuapp.com/v1/polls',
      getPolls: 'https://test-straw-poll-restapi.herokuapp.com/v1/polls',
      getActivePolls: 'https://test-straw-poll-restapi.herokuapp.com/v1/polls/active',
      voteAnswer: 'https://test-straw-poll-restapi.herokuapp.com/v1/polls/{pollId}/vote',
      voteAnswerWsReply: '/v1/rt-voting/{pollId}/reply',
      changePoll: 'https://test-straw-poll-restapi.herokuapp.com/v1/polls/{pollId}',
      webSockerAnswers: 'https://test-straw-poll-restapi.herokuapp.com/v1/rt-voting',
      login: 'https://test-straw-poll-restapi.herokuapp.com/v1/auth/token'
    }
  },
  stomp: {
    disableDebug: false
  }
};
